import styled from 'styled-components'
import { theme } from '@/styles/theme'

export const PostContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    padding: 0;
  }
  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    width: 750px;
  }
`

export const Title = styled.h1`
  line-height: 1.4;
  font-weight: 500;
`

export const Date = styled.time`
  display: flex;
  text-align: end;

  span {
    display: inline-block;
    padding: 0 5px;
    margin: 0 10px;
    background: red;
    transform: skew(-30deg);

    p {
      margin: 5px;
      font-weight: 600;
      color: white;
      transform: skew(30deg);
    }
  }
`

export const PostContent = styled.div`
`
