import React from 'react'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'

import Layout from '@/layouts/GeneralLayout'
import SEO from '@/components/seo'

import { PostContainer, Title, Date, PostContent } from './styled.js'

function Template({ data }) {
  const { frontmatter, html } = data.markdownRemark
  const datetime = dayjs(frontmatter.date).toISOString()
  const year = dayjs(frontmatter.date).format('YYYY')
  const date = dayjs(frontmatter.date).format('MM/DD')

  return (
    <Layout>
      <SEO {...frontmatter} />
      <PostContainer>
        <Title>{frontmatter.title}</Title>
        <Date datetime={datetime} date-updated="true">
          <span>
            <p>{year}</p>
          </span>
          {date}
        </Date>

        <PostContent dangerouslySetInnerHTML={{ __html: html }} />
      </PostContainer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        description
        keys
        tags
        hot
        keys
        cover {
          childImageSharp {
            fluid {
              originalImg
            }
          }
        }
      }
    }
  }
`

export default Template
