import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import avatar from '@/images/avatar.jpeg'
import { STATIC_ROUTER_PATH } from '@/common/constants/routers'

import {
  LogoMini,
  MenuButtonMini,
  NavbarContainer,
  PageNavbarMini,
  Nav,
} from './styled'
import Header from '@/components/Header/index'

function HeaderMini({ open, setOpen }) {
  const title = "EngineLin's Blog"
  const HEX_OFFICE_WEBSITE = 'https://16n.com.tw'
  const navList = [
    { item: STATIC_ROUTER_PATH.HOME, name: '文章' },
    { item: STATIC_ROUTER_PATH.ABOUTME, name: '關於我' },
    { item: HEX_OFFICE_WEBSITE, name: '合作' },
  ]

  return (
    <PageNavbarMini>
      <NavbarContainer>
        <LogoMini to={STATIC_ROUTER_PATH.HOME} title={title} alt={title}>
          <img src={avatar} alt={title} />
          <h1>{title}</h1>
        </LogoMini>

        <Nav className={open && 'open'}>
          <ul>
            {navList.map(({ item, name }) => (
              <li key={name}>
                <Link to={item} alt={name} title={name}>
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </Nav>

        <MenuButtonMini type="button" onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={open ? faTimes : faBars} />
        </MenuButtonMini>
      </NavbarContainer>
    </PageNavbarMini>
  )
}

HeaderMini.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

HeaderMini.defaultProps = {
  active: false,
  open: false,
  setOpen: () => {},
}

export default HeaderMini
