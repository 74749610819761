import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Header from '@/components/Header/mini.jsx'
import Footer from '@/components/Footer/index.jsx'
import BackTop from '@/components/BackTop/index.jsx'
import { Container } from '@/layouts/styled'

function ScrollHeaderLayout({ children }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Header open={open} setOpen={setOpen} />
      <Container>
        <main>{children}</main>
      </Container>
      <Footer />
      <BackTop />
    </>
  )
}

ScrollHeaderLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollHeaderLayout
